/*---------------------------
    Screenshort Area
--------------------------*/
.screenshort-area{
    padding: 113px 0 115px 0;
    position: relative;
    z-index: 0;
    .section-title{
        .title{
            margin-bottom: 10px;
        }
    }
    &.screenshort-bg{
        background-image: url(../../img/bg/screenshort-bg.png);
        background-position: center;
        background-size: cover;
    }
}
.single-screenshort-item{
  padding: 0 15px;
  img{
    width: 100%;
  }
}
.screenshort-carousel{

}
