/*--------------------
    Sidebar Area
--------------------*/
//f7f8fc
.sidebar{
    .widget-area {
        background-color: #f7f8fc;
        margin-bottom: 20px;
        padding: 25px 30px 30px 30px;
        &:last-child{
            margin-bottom: 0;
        }
        &.search{
            padding: 0;
            .widget-body{
                .searchform{
                    position: relative;
                    .form-group{
                        .form-control{
                            border: 2px solid #f2f2f2;
                            height: 50px;
                            padding: 20px;
                            padding-right: 50px;
                        }
                    }
                    .btn-submit{
                      position: absolute;
                      right: 20px;
                      top: 0;
                      background-color: transparent;
                      border: none;
                      cursor: pointer;
                      height: 50px;
                      color: #505b6d;
                      width: auto;
                      line-height: 50px;
                      padding: 0;
                    }
                }
            }
        }
        .widget-title{
            .title{
                font-size: 24px;
                line-height: 34px;
                font-weight: 700;
                margin-bottom: 21px;
            }
        }
        .widget-body{

        }
        &.category{
            .widget-body{
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        display: block;
                        margin: 8px 0;
                        &:first-child{
                            margin-top: 0;
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        a{
                            color: #505b6d;
                            @include transition($transition);
                            .right{
                                float: right;
                            }
                            &:hover{
                                color: $base-color;
                            }
                        }

                    }
                }
            }
        }
        &.latest-post{
            .widget-body{
                ul{
                    li{
                         border-bottom: 2px dotted rgba(#0d154b,.4);
                         padding: 20px 0 20px 0;
                         &:first-child{
                             padding-top: 0;
                         }
                         &:last-child{
                             padding-bottom: 0;
                             border: none;
                         }
                         .single-latest-post{
                             display: flex;
                             align-items: flex-start;
                             .thumb{
                                 margin-right: 30px;
                             }
                             .content{
                                 flex: 1;
                                 .title{
                                     font-size: 16px;
                                     line-height: 20px;
                                     @include transition($transition);
                                     margin-bottom: 0px;
                                     &:hover{
                                         color: $base-color;
                                     }
                                 }
                                 .posted-by{
                                     font-size: 14px;
                                     line-height: 24px;
                                     color: #505b6d;
                                 }
                             }
                         }
                    }
                }
            }
        }
        &.tags{
            padding-bottom: 25px;
            .widget-body{
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        display: inline-block;
                        a{
                            display: block;
                            border: 1px solid #b1b3b9;
                            border-radius: 30px;
                            padding: 5px 15px;
                            margin: 5px;
                            color: #505b6d;
                            @include transition($transition);
                            font-size: 14px;
                            &:hover{
                                background-color: #0d154b;
                                background-image: -moz-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                                background-image: -webkit-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                                background-image: -ms-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
