/*--------------------
    Blog Details
--------------------*/
.blog-details-page-content-area{
    padding: 120px 0 104px 0;
    .blog-details-content-area{
        .entry-content{
            .thumb{
                position: relative;
                margin-bottom: 22px;
                .time{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    text-align: center;
                    background-color: $base-color;
                    color: $white;
                    background-image: linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                    background-image: -moz-linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                    background-image: -webkit-linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                    background-image: -ms-linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                    .date{
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 40px;
                        display: block;
                    }
                    .month{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        display: block;
                    }
                }
            }
            .title{
                color: #252a32;
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 22px;
            }
            .post-meta{
                margin-bottom: 19px;
                li{
                    display: inline-block;
                    margin: 0 8px;
                    color: #505b6d;
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
            p{
                color: #505b6d;
                font-size: 16px;
                line-height: 26px;
            }
            blockquote{
                background-color: #f7f8fc;
                padding: 25px 30px 28px 50px;
                color: #505b6d;
                position: relative;
                margin-left: 50px;
                .icon{
                    background-image: linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -moz-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -webkit-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -ms-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    box-shadow: 0px 3px 20px 0px rgba(13, 21, 75, 0.3);
                    display: inline-block;
                    position: absolute;
                    left: -15px;
                    top: 43px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    color: $white;
                    text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                }
            }
        }
        .entry-footer{
            border-top: 1px solid rgba(13, 21, 75, 0.1);
            border-bottom: 1px solid rgba(13, 21, 75, 0.1);
            padding: 20px 0 25px 0;
            margin-top: 35px;
            .left-content{
                display: inline-block;
                margin-top: 10px;
                .posted_by{
                    font-size: 16px;
                    font-weight: 600;
                    color: #252a32;
                    a{
                        color: #505b6d;
                        @include transition($transition);
                        margin-left: 5px;
                        &:hover{
                            color: $base-color;
                        }
                    }
                }
            }
            .right-content{
                display: inline-block;
                float: right;
                ul{
                    li{
                        display: inline-block;
                        margin: 0 5px;
                        &.title{
                            font-size: 16px;
                            font-weight: 600;
                            color: #252a32;
                        }
                        a{
                            color: #95979a;
                            display: block;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid rgba(13, 21, 75, 0.1);
                            line-height: 40px;
                            text-align: center;
                            @include transition($transition);
                            &:hover{
                                color: $white;
                                box-shadow: 0px 6px 10px 0px rgba(14, 15, 18, 0.2);
                                background-image: linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                                background-image: -moz-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                                background-image: -webkit-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                                background-image: -ms-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                            }
                        }
                    }
                }
            }
        }
        .entry-comment{
            margin-top: 55px;
            .title{
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 30px;
            }
            ul{
                li{
                    margin-bottom: 30px;
                    .single-comment-item{
                        &.reply{
                            margin-left: 50px;
                        }
                        padding: 40px 60px 33px 40px;
                        position: relative;
                        box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.05);
                        display: flex;
                        align-items: flex-start;
                        .reply{
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            background-image: -moz-linear-gradient( -34deg, rgb(0,132,255) 0%, rgb(68,86,255) 19%, rgb(136,40,255) 72%);
                            background-image: -webkit-linear-gradient( -34deg, rgb(0,132,255) 0%, rgb(68,86,255) 19%, rgb(136,40,255) 72%);
                            background-image: -ms-linear-gradient( -34deg, rgb(0,132,255) 0%, rgb(68,86,255) 19%, rgb(136,40,255) 72%);
                            color: $white;
                            height: 45px;
                            width: 45px;
                            display: inline-block;
                            line-height: 45px;
                            text-align: center;
                            font-size: 24px;
                            @include transition($transition);
                            &:hover{
                                box-shadow: 0px 6px 10px 0px rgba(14, 15, 18, 0.2);
                            }
                        }
                        .thumb{
                            margin-right: 20px;
                            img{
                                border-radius: 50%;
                                border: 2px solid $base-color;
                            }
                        }
                        .content{
                            flex: 1;
                            .title{
                                font-size: 18px;
                                line-height: 28px;
                                margin-bottom: 0;
                            }
                            p{
                                font-size: 16px;
                                line-height: 26px;
                                color: #505b6d;
                                margin-bottom: 0;
                            }
                            .time{
                                font-size: 14px;
                                line-height: 24px;
                                color: #505b6d;
                                margin-bottom: 15px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .entry-commentform{
        margin-top: 50px;
        .title{
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 30px;
        }
        .commentform{
            .form-group{
                &.textarea{
                    .form-control{
                        resize: none;
                        min-height: 140px;
                        &:focus{
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
                .form-control{
                    background-color: #f7f8fc;
                   @include placeholder-color(#505b6d);
                   border: none;
                   padding: 20px;
                   height: 50px;
                }
            }
            .btn-submit{
                background-image: linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                background-image: -moz-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                background-image: -webkit-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                background-image: -ms-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                border: none;
                color: $white;
                height: 50px;
                width: 180px;
                @include transition($transition);
                line-height: 50px;
                padding:0px;
                &:hover{
                    box-shadow: 0px 3px 20px 0px rgba(13, 21, 75, 0.3);
                }
            }
        }
    }
}
