/*------------------------
    About area
------------------------*/
.about-area{
    padding: 120px 0;
    .left-content-area{
        .title{
            font-size: 30px;
            color: #333333;
            line-height: 40px;
            margin-bottom: 20px;
            font-weight: 600;
        }
        p{
            font-size: 14px;
            line-height: 26px;
            color: #777777;
        }
        .btn-wrapper{
            margin-top: 32px;
            .boxed-btn{
                width: 160px;
                &:hover{
                    background-color: $base-color;
                    box-shadow: 0 0 25px 0 rgba($base-color,.6);
                }
            }
        }
    }
    .right-content-area{
        .img-wrapper{
            display: inline-block;
            position: relative;
            border-radius: 20px;
            img{
                border-radius: 20px;
            }
            .hover{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .video-play-btn{
                    background-color: $base-color;
                    color: $white;
                    &:before{
                        background-color: $base-color;
                    }
                }
            }
        }
    }
}