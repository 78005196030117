/*--------------------
    Blog Page
--------------------*/
.blog-page-content-area{
    padding: 120px 0 120px 0;
    .single-blog-post-item{
        margin-bottom: 33px;
        .thumb{
            position: relative;
            margin-bottom: 22px;
            .time{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 65px;
                height: 65px;
                line-height: 65px;
                text-align: center;
                background-color: $base-color;
                color: $white;
                background-image: linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                background-image: -moz-linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                background-image: -webkit-linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                background-image: -ms-linear-gradient( -38deg, rgb(136,40,255) 28%, rgb(68,86,255) 81%, rgb(0,132,255) 100%);
                .date{
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 40px;
                    display: block;
                }
                .month{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    display: block;
                }
            }
        }
        .content{
            .post-meta{
                li{
                    display: inline-block;
                    color: #505b6d;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0 10px;
                    &:first-child{
                        margin-left: 0;
                    }
                    a{
                        color: #505b6d;
                        @include transition($transition);
                        &:hover{
                            color: $base-color;
                        }
                    }
                }
            }
            .title{
                font-size: 30px;
                line-height: 40px;
                color: #252a32;
                @include transition($transition);
                margin-bottom: 20px;
                &:hover{
                    color: $base-color;
                }
            }
            p{
                font-size: 16px;
                line-height: 26px;
                color: #505b6d;
            }
            .readmore{
                font-size: 14px;
                text-transform: uppercase;
                line-height: 24px;
                color: #252a32;
                @include transition($transition);
                font-weight: 600;
                &:hover{
                    color: $base-color;
                }
            }
        }
    }
}
.blog-pagination{
    text-align: center;
    .pagination{
        display: block;
        .page-item{
            display: inline-block;
            margin: 0 5px;
            &.active{
               .page-link{
                    background-image: linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -moz-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -webkit-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -ms-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    box-shadow: 0px 9px 20px 0px rgba(9, 126, 255, 0.3);
                    color: $white;
               }
            }
            .page-link{
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: $white;
                color: #252a32;
                font-weight: 600;
                padding: 0;
                border-radius: 0;
                box-shadow: 0px 4px 10px 0px rgba(12, 124, 255, 0.15);
                @include transition($transition);
                border: none;
                &:hover{
                    background-image: linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -moz-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -webkit-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    background-image: -ms-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
                    box-shadow: 0px 9px 20px 0px rgba(9, 126, 255, 0.3);
                    color: $white;
                }
            }
        }
    }
}
